import { Button, Grid, Pagination } from "@mui/material";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import { ImageDeleteModalStyled, ImageListGridStyled, ImageListPaginationStyled, ImageListStyled } from "./styled";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import GridCard from "@/components/kit/GridCard";
import InitLoading from "@/components/app/Loading/InitLoading";
import { GRID_PER_PAGE } from "@/constants/grid-view-configs";
import { ImageFilterBody } from "@/api/types/image";
import { useDeleteImage, useGetImageListPost } from "@/api/hooks/useImage";
import { routesByEntity } from "@/constants/routes-by-entity";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

const ImageList: React.FC = () => {
    const deleteAccess = useCheckPermissionAccess(PagesAccess.IMAGE, OperationAccess.DELETE);
    const [queryParams, setQueryParams] = useState<ImageFilterBody>({
        skip: 0,
        take: 24,
        search: "",
    });

    const { mutate: deleteImage } = useDeleteImage();
    const { data, isLoading, refetch } = useGetImageListPost(queryParams);

    const handleDelete = (id: number) => {
        if (data && data.data && data.data.items) {
            const list = data?.data.items;

            const imageIndex = list.findIndex((item) => item.id === id);
            const selectedImage = list[imageIndex];

            if (selectedImage.assignedTo && selectedImage.assignedTo?.length > 0) {
                modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                    header: "Warning: Image in use!",
                    text: (
                        <ImageDeleteModalStyled>
                            <p>
                                You are about to delete this image. However, this image is currently being used by the
                                following items. If you wish to delete it, you must first unassign the image from the
                                content.
                            </p>
                            <ul>
                                {selectedImage.assignedTo?.map((item, index) => {
                                    if (routesByEntity[item.entity.toUpperCase()](id)) {
                                        return (
                                            <li key={index}>
                                                <Link target="_blank" to={routesByEntity[item.entity.toUpperCase()](id)}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        );
                                    }

                                    return <li key={index}>Profile Avatar</li>;
                                })}
                            </ul>
                        </ImageDeleteModalStyled>
                    ),
                    handleAction: () => deleteImage(id, { onSuccess: () => refetch() }),
                    cancelButtonText: "Close",
                    disableSubmitButton: true,
                });
            } else {
                modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                    header: "Delete Confirmation",
                    text: "Are you sure to delete this image?",
                    handleAction: () => deleteImage(id, { onSuccess: () => refetch() }),
                });
            }
        }
    };

    const handleOpen = (imageUrl: string) => {
        modalActions.addModal(MODAL_TYPES.IMAGE_PREVIEW, {
            header: "Image Preview",
            width: "832px",
            imageUrl,
        });
    };

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: GRID_PER_PAGE, skip: (page - 1) * GRID_PER_PAGE }));
    }, []);

    if (isLoading && !data) return <InitLoading />;

    return (
        <>
            <DashboardHeader
                title="Images"
                titleSuffix={
                    <Button
                        color="primary"
                        component={Link}
                        to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ADD_NEW.ROOT.ABSOLUTE}
                        variant="contained"
                    >
                        Add New Image
                    </Button>
                }
            />
            <DashboardContent>
                <ImageListStyled>
                    <ImageListGridStyled loading={isLoading}>
                        <Grid container spacing={4}>
                            {data?.data.items.map((item) => (
                                <Grid key={item.id} item lg={2} md={3} xs={6}>
                                    <GridCard
                                        actions={
                                            <>
                                                <PreviewIcon color="inherit" onClick={() => handleOpen(item.url)} />
                                                {deleteAccess ? (
                                                    <DeleteIcon color="inherit" onClick={() => handleDelete(item.id)} />
                                                ) : null}
                                            </>
                                        }
                                        image={item.url}
                                        title={item.title.length ? item.title : item.originalName}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </ImageListGridStyled>
                    {data?.data.pagination && data.data.pagination?.totalItems > GRID_PER_PAGE ? (
                        <ImageListPaginationStyled>
                            <Pagination
                                count={data.data.pagination.totalPages}
                                disabled={isLoading}
                                page={data.data.pagination.currentPage}
                                onChange={(e, page) => handlePaginationModelChange(page)}
                            />
                        </ImageListPaginationStyled>
                    ) : null}
                </ImageListStyled>
            </DashboardContent>
        </>
    );
};

export default ImageList;
