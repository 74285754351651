import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

import PublisherPageStyle from "./style";
import ShowPublishers from "./show";

import DashboardHeader from "@/components/app/DashboardHeader";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export default function Publisher() {
    const createAccess = useCheckPermissionAccess(PagesAccess.PUBLISHER, OperationAccess.CREATE);

    return (
        <PublisherPageStyle>
            <DashboardHeader
                title="Publishers"
                titleSuffix={
                    createAccess ? (
                        <Button
                            color="primary"
                            component={Link}
                            to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_PUBLISHER.ROOT.ABSOLUTE}
                            variant="contained"
                        >
                            Add New Publisher
                        </Button>
                    ) : null
                }
            />
            <Box className="center">
                <ShowPublishers />
                <br />
            </Box>
        </PublisherPageStyle>
    );
}
