import { Alert, Button, Menu, MenuItem, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { contentListColumnSchema } from "./column-schema";

import Table from "@/components/kit/Table";
import { ITableParams } from "@/types/table";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { getAxiosError } from "@/utils/get-axios-error";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { contentService } from "@/api/services/content";
import { IContentListResponse } from "@/api/types/content";
import { filterListNames } from "@/constants/filter-list-names";
import SortAndFilters from "@/components/app/SortAndFilters";
import { IGetListBody } from "@/api/types/base-data";
import { DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import { ILazyParams } from "@/types/filters";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

const ContentList: React.FC = () => {
    const createAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.CREATE);
    const deleteAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.DELETE);
    const showAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.LIST);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filters, setFilters] = useState<IGetListBody>();
    const [contents, setContents] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState<ILazyParams>({
        filter: {},
        first: 0,
        page: 0,
        rows: DEFAULT_PAGE_SIZE,
        sort: {},
    });

    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const fetchContents = useCallback(
        async (queryParams?: ITableParams) => {
            try {
                setLoading(true);
                const body = {
                    ...filters,
                    skip: lazyParams.page * DEFAULT_PAGE_SIZE,
                    take: lazyParams.rows,
                    ...queryParams,
                };

                if (lazyParams.sort && JSON.stringify(lazyParams.sort) !== "{}") {
                    body.sort = lazyParams.sort;
                }
                const result = await contentService.list(body);

                setContents(result);
                // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
            } catch (err) {
                enqueueSnackbar("Failed to load contents", { variant: "error" });
            } finally {
                setLoading(false);
            }
        },
        [filters, lazyParams]
    );

    useEffect(() => {
        fetchContents();
    }, [fetchContents, filters, lazyParams]);

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page: 0,
        });
    };

    const suffixCols = useCallback(
        (
            tableData: IContentListResponse[],
            setTableData: Dispatch<SetStateAction<IContentListResponse[]>>
        ): GridColDef[] => {
            const handleDelete = async (id: number) => {
                try {
                    setLoading(true);
                    await contentService.delete(id);
                    enqueueSnackbar(`Content deleted successfully`, { variant: "success" });
                    setTableData((prev) => prev.filter((item) => item.id !== id));
                } catch (err) {
                    const error = getAxiosError(err);

                    enqueueSnackbar(error?.meta?.message || "Server Error", { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            return [
                {
                    field: "actions",
                    type: "actions",
                    headerName: "Actions",
                    getActions: (params: GridRowParams<IContentListResponse>) => [
                        ...(showAccess
                            ? [
                                  <Link
                                      key={params.row.id}
                                      to={
                                          ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.SHOW.SHOW_BY_ID(params.row.id).ABSOLUTE
                                      }
                                  >
                                      <GridActionsCellItem icon={<ArrowForwardIcon color="action" />} label="Show" />
                                  </Link>,
                              ]
                            : []),
                        ...(deleteAccess
                            ? [
                                  <GridActionsCellItem
                                      key={params.row.id}
                                      icon={<DeleteIcon />}
                                      label="Delete"
                                      onClick={() => handleDelete(params.row.id)}
                                  />,
                              ]
                            : []),
                    ],
                },
            ];
        },
        [deleteAccess, showAccess]
    );

    return (
        <>
            <DashboardHeader
                title="Content"
                titleSuffix={
                    createAccess && (
                        <>
                            <Button
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                color="primary"
                                endIcon={<KeyboardArrowDownIcon />}
                                id="basic-button"
                                variant="contained"
                                onClick={handleClick}
                            >
                                Add New Content
                            </Button>
                            <Menu anchorEl={anchorEl} id="basic-menu" open={open} onClose={handleClose}>
                                <MenuItem
                                    component={Link}
                                    to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_MOVIE.ROOT.ABSOLUTE}
                                >
                                    Create Movie
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ADD_NEW_SERIES.ROOT.ABSOLUTE}
                                >
                                    Create Series
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
            >
                <Typography className="p">Create the content with all the details and see the list of content.</Typography>
                <Typography className="p">Sort the content by list and create new videos or series.</Typography>
                <Typography className="p">
                    Additionally, you can view the table of contents and search through the content library.
                </Typography>
                <Alert severity="info" sx={{ marginTop: "1em" }}>
                    Note: First, you have to create tags and genres before creating the content!
                </Alert>
            </DashboardHeader>
            <SortAndFilters listName={filterListNames.CONTENTS} onChange={onSortAndFiltersChanged} />
            <DashboardContent>
                <Table
                    columnsSchema={contentListColumnSchema}
                    lazyParams={lazyParams}
                    loading={loading}
                    service={contents || []}
                    setLazyParams={setLazyParams}
                    showSearch={false}
                    suffixCols={suffixCols}
                />
            </DashboardContent>
        </>
    );
};

export default ContentList;
