/* eslint-disable no-unused-vars */
export enum USER_ROLE {
    SUPERADMIN = "SuperAdmin",
    ADMINISTRATOR = "Administrator",
    CONTENT_MANAGER = "Content Manager",
    CUSTOMER_SERVICE = "Customer Service",
    ANALYTICS_MANAGER = "Analytics Manager",
    FINANCE_MANAGER = "Finance Manager",
}

export enum USER_STATE {
    ENABLED = "Enable",
    DISABLED = "Disabled",
}
