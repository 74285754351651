import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import StaticList from "@/pages/Settings/Static";
import StaticForm from "@/pages/Settings/Static/AddUpdate";
import ShowCountries from "@/pages/Settings/Countries/show";
import CountryDetail from "@/pages/Settings/Countries/detail";
import Plans from "@/pages/Settings/Plans";
import PlansCreate from "@/pages/Settings/Plans/create";
import PlansEdit from "@/pages/Settings/Plans/edit";
import PlansDetail from "@/pages/Settings/Plans/details";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export const ProtectedCountriesList = withCheckPermissions(ShowCountries, PagesAccess.COUNTRY, OperationAccess.LIST);
export const ProtectedCountriesDetails = withCheckPermissions(CountryDetail, PagesAccess.COUNTRY, OperationAccess.LIST);

export const ProtectedStaticList = withCheckPermissions(StaticList, PagesAccess.STATIC, OperationAccess.LIST);
export const ProtectedStaticFormUpdate = withCheckPermissions(StaticForm, PagesAccess.STATIC, OperationAccess.UPDATE);
export const ProtectedStaticFormCreate = withCheckPermissions(StaticForm, PagesAccess.STATIC, OperationAccess.CREATE);

export const ProtectedPlanList = withCheckPermissions(Plans, PagesAccess.PLANS, OperationAccess.LIST);
export const ProtectedPlanFormUpdate = withCheckPermissions(PlansEdit, PagesAccess.PLANS, OperationAccess.UPDATE);
export const ProtectedPlanFormCreate = withCheckPermissions(PlansCreate, PagesAccess.PLANS, OperationAccess.CREATE);
export const ProtectedPlanFormDetails = withCheckPermissions(PlansDetail, PagesAccess.PLANS, OperationAccess.LIST);
