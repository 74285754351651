import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse, IPaginatedRequestBody } from "@/types/axios-response";
import { ITableResponse } from "@/types/table";
import {
    IAgeRangeListResponse,
    IContentCreatePayload,
    IContentListResponse,
    IContentShowResponse,
    IContentUpdatePayload,
    ICreateEpisodePayload,
    ICreateSeasonPayload,
    IContentsParams,
    IContentsReponse,
} from "@/api/types/content";
import { ILanguagePayload } from "@/types/language-payload";
import { serializeObject } from "@/utils/serializer";

export const GetContents = async (params: IContentsParams) => {
    const result = await AxiosInstance.get<IContentsReponse>(`${API_ROUTES.CONTENT.LIST}?${serializeObject(params)}`);

    return result.data;
};

export const contentService = {
    list(params?: IPaginatedRequestBody) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IContentListResponse>>>(API_ROUTES.CONTENT.LIST, params);
    },
    create(data: IContentCreatePayload) {
        return AxiosInstance.post<IAxiosResponse<IContentListResponse>>(API_ROUTES.CONTENT.CREATE, data);
    },
    update(id: number, data: IContentUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse<IContentListResponse>>(API_ROUTES.CONTENT.UPDATE(id), data);
    },
    createSeason(data: ICreateSeasonPayload) {
        return AxiosInstance.post<IAxiosResponse<IContentListResponse>>(API_ROUTES.CONTENT.CREATE, data);
    },
    updateSeason(id: number, data: ICreateSeasonPayload) {
        return AxiosInstance.put<IAxiosResponse<IContentListResponse>>(API_ROUTES.CONTENT.UPDATE(id), data);
    },
    createEpisode(data: ICreateEpisodePayload) {
        return AxiosInstance.post<IAxiosResponse<IContentListResponse>>(API_ROUTES.CONTENT.CREATE, data);
    },
    updateEpisode(id: number, data: ICreateEpisodePayload) {
        return AxiosInstance.put<IAxiosResponse<IContentListResponse>>(API_ROUTES.CONTENT.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<ITableResponse<IContentListResponse>>>(API_ROUTES.CONTENT.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IContentShowResponse>>(API_ROUTES.CONTENT.SHOW(id));
    },
    type(params?: ILanguagePayload) {
        return AxiosInstance.get<IAxiosResponse<ITableResponse<IContentListResponse>>>(API_ROUTES.CONTENT.TYPE, {
            params,
        });
    },
    ageRange(params?: ILanguagePayload) {
        return AxiosInstance.get<IAxiosResponse<ITableResponse<IAgeRangeListResponse>>>(API_ROUTES.CONTENT.AGE_RANGE, {
            params,
        });
    },
};
