import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import VideoList from "@/pages/MediaManagement/Video";
import VideoFormAdd from "@/pages/MediaManagement/Video/AddVideo";
import VideoFormUpdate from "@/pages/MediaManagement/Video/EditVideo";
import VideoDetails from "@/pages/MediaManagement/Video/Show";
import ImageList from "@/pages/MediaManagement/Image";
import ImageForm from "@/pages/MediaManagement/Image/AddUpdate";
import AvatarList from "@/pages/MediaManagement/Avatar";
import AvatarForm from "@/pages/MediaManagement/Avatar/AddUpdate";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export const ProtectedVideoList = withCheckPermissions(VideoList, PagesAccess.VIDEO, OperationAccess.LIST);
export const ProtectedVideoUpdate = withCheckPermissions(VideoFormUpdate, PagesAccess.VIDEO, OperationAccess.UPDATE);
export const ProtectedVideoCreate = withCheckPermissions(VideoFormAdd, PagesAccess.VIDEO, OperationAccess.CREATE);
export const ProtectedVideoDetails = withCheckPermissions(VideoDetails, PagesAccess.VIDEO, OperationAccess.DETAIL);

export const ProtectedImageList = withCheckPermissions(ImageList, PagesAccess.IMAGE, OperationAccess.LIST);
export const ProtectedImageForm = withCheckPermissions(ImageForm, PagesAccess.IMAGE, OperationAccess.CREATE);

export const ProtectedAvatarList = withCheckPermissions(AvatarList, PagesAccess.IMAGE, OperationAccess.LIST);
export const ProtectedAvatarCreate = withCheckPermissions(AvatarForm, PagesAccess.IMAGE, OperationAccess.CREATE);
