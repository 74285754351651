import { Box, Divider, Pagination, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";

import MakeSerialStyle from "./style";
import SerialCard from "./serialCard";
import AddCreateSeasonModal from "./formModal";

import { useContentsList } from "@/api/hooks/useContents";
import { IContentShowResponse } from "@/api/types/content";

// TODO: make states optimize and use zustand if you wish
const TakePerPage = 8;

export default function AddSessions() {
    const { id: serialIdString } = useParams();
    const [edit, setEdit] = useState<IContentShowResponse | null>(null);
    const [open, setOpen] = useState(false);

    const serialId = Number(serialIdString);
    const [params, setQueryParams] = useState({ take: TakePerPage, skip: 0, filter: { parentId: Number(serialIdString) } });
    const { data: seasonData, isFetching } = useContentsList(params);

    const contentList = seasonData?.data.items || [];

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: TakePerPage, skip: (page - 1) * TakePerPage }));
    }, []);

    const handleEdit = (data: IContentShowResponse) => {
        setEdit(data);
    };
    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        if (edit) setEdit(null);
        setOpen(false);
    };

    const handleSuccess = () => {
        setEdit(null);
    };

    const seasonNumbers = contentList.length > 0 ? contentList.map((season) => season.season).sort() : [0];
    const maxNumber = seasonNumbers[seasonNumbers.length - 1];

    return (
        <MakeSerialStyle>
            <Box className="page-header">
                <LoadingButton className="episode-btn" type="submit" variant="contained" onClick={handleClickOpen}>
                    Create Season
                </LoadingButton>
            </Box>
            <Divider />
            <Box className="page-content">
                {isFetching && <Typography>Loading</Typography>}
                <Box className="serial-list">
                    {contentList.map((serial) => {
                        return <SerialCard key={serial.id} id={serial.id} onEdit={handleEdit} />;
                    })}
                </Box>
            </Box>
            <Pagination
                count={seasonData?.data.pagination.totalPages || 0}
                disabled={isFetching}
                page={seasonData?.data.pagination.currentPage || 0}
                sx={{ direction: "ltr" }}
                onChange={(e, page) => handlePaginationModelChange(page)}
            />
            {serialId && (
                <AddCreateSeasonModal
                    data={edit}
                    maxNumber={maxNumber}
                    open={open || !!edit}
                    parentId={serialId}
                    seasonNumbers={seasonNumbers || []}
                    onClose={handleClose}
                    onSettled={() => setEdit(null)}
                    onSuccess={handleSuccess}
                />
            )}
        </MakeSerialStyle>
    );
}
