import dayjs from "dayjs";

import { ICustomer } from "@/api/types/customer";

export type ICustomerWeNeed = Omit<ICustomer, "createdAt" | "active" | "isDeleted" | "isTrialUsed" | "role" | "userType">;

export function createData(data: ICustomer): ICustomerWeNeed {
    const { id, firstName, lastName, phoneNumber, emailVerified, email, state, updatedAt, hasSubscription } = data;

    return {
        id,
        firstName,
        lastName,
        phoneNumber,
        emailVerified,
        email: email || "",
        state,
        hasSubscription,
        updatedAt: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
    };
}
