// import { withProtectedRoutes } from "./ProtectedRoute";
import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import CustomerList from "@/pages/UserManagement/Customer";
import CustomerForm from "@/pages/UserManagement/Customer/AddUpdate";
import CustomerShow from "@/pages/UserManagement/Customer/Details";
// import UserManagement from "@/pages/UserManagement";
import UserList from "@/pages/UserManagement/User";
import UserForm from "@/pages/UserManagement/User/AddUpdate";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

// const UserManagementProtected = withProtectedRoutes(UserManagement, [PERMISSIONS.USER_LIST]);
export const ProtectedUserList = withCheckPermissions(UserList, PagesAccess.USER, OperationAccess.LIST);
export const ProtectedUserFormUpdate = withCheckPermissions(UserForm, PagesAccess.USER, OperationAccess.UPDATE);
export const ProtectedUserFormCreate = withCheckPermissions(UserForm, PagesAccess.USER, OperationAccess.CREATE);

export const ProtectedCustomerList = withCheckPermissions(CustomerList, PagesAccess.CUSTOMER, OperationAccess.LIST);
export const ProtectedCustomerFormUpdate = withCheckPermissions(CustomerForm, PagesAccess.CUSTOMER, OperationAccess.UPDATE);
export const ProtectedCustomerFormDetails = withCheckPermissions(CustomerShow, PagesAccess.CUSTOMER, OperationAccess.LIST);
