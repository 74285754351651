import { useCallback, useState } from "react";
import { Box, Divider, Pagination, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

import ShowEpisodes from "./showEpisodes";
import EachEpisodeStyle from "./style";
import FormModal from "./formModal";

import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { IContentListResponse } from "@/api/types/content";
import { useContentsList, useDeleteContent } from "@/api/hooks/useContents";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

interface IEachEpisode {
    sessionId: number;
}

const TakePerPage = 7;

export default function EachEpisode(props: IEachEpisode) {
    const { sessionId } = props;
    const [open, setOpen] = useState(false);
    const [editEpisode, setEditEpisode] = useState<IContentListResponse | null>(null);
    const { deleteContent } = useDeleteContent();
    const { enqueueSnackbar } = useSnackbar();
    const [params, setQueryParams] = useState({ take: TakePerPage, skip: 0, filter: { parentId: Number(sessionId) } });
    const { data: episodesData, isFetching } = useContentsList(params);

    const episodeList = episodesData?.data?.items || [];

    const editAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.UPDATE);
    const deleteAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.DELETE);

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: TakePerPage, skip: (page - 1) * TakePerPage }));
    }, []);

    const handleDelete = (id: number) => {
        if (!deleteAccess) {
            enqueueSnackbar("You don't have access for deleting an episode");

            return;
        }
        deleteContent(id);
    };

    const episodeNumbers = episodeList.length > 0 ? episodeList.map((item) => item.episode).sort() : [0];

    const maxNumber = episodeNumbers[episodeNumbers.length - 1];

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        if (editEpisode) setEditEpisode(null);
        setOpen(false);
    };

    const handleEdit = (data: IContentListResponse) => {
        if (!editAccess) {
            enqueueSnackbar("You don't have access for editing an episode");

            return;
        }
        setEditEpisode(data);
    };

    return (
        <EachEpisodeStyle className="each-session">
            <Box className="page-header">
                <LoadingButton className="episode-btn" type="submit" variant="contained" onClick={handleClickOpen}>
                    Create Episode
                </LoadingButton>
            </Box>
            <Divider />
            <Box className="page-content">
                {episodeList.length === 0 ? (
                    <Typography sx={{ margin: "1em 0" }}>You did not define any Episodes yet</Typography>
                ) : (
                    <>
                        <Typography className="title">Episodes List</Typography>
                        <ShowEpisodes data={episodeList} onDelete={handleDelete} onEdit={handleEdit} />
                    </>
                )}
            </Box>
            <Pagination
                count={episodesData?.data.pagination.totalPages || 0}
                disabled={isFetching}
                page={episodesData?.data.pagination.currentPage || 0}
                sx={{ direction: "ltr" }}
                onChange={(e, page) => handlePaginationModelChange(page)}
            />

            <FormModal
                data={editEpisode}
                episodeNumbers={episodeNumbers}
                maxNumber={maxNumber || 1}
                open={open || !!editEpisode}
                parentId={sessionId}
                onClose={handleClose}
            />
        </EachEpisodeStyle>
    );
}
