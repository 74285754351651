/* eslint-disable no-unused-vars */
export type Access = "List" | "Detail" | "Create" | "Update" | "Delete" | "Self" | "All";
export type Page =
    | "user"
    | "customer"
    | "image"
    | "video"
    | "avatar"
    | "tag"
    | "genre"
    | "publisher"
    | "content"
    | "ui_home"
    | "static"
    | "country"
    | "plans"
    | "all";

export enum OperationAccess {
    LIST = "List",
    DETAIL = "Detail",
    CREATE = "Create",
    UPDATE = "Update",
    DELETE = "Delete",
    SELF = "Self",
    ALL = "All",
}

export enum PagesAccess {
    USER = "user",
    CUSTOMER = "customer",
    IMAGE = "image",
    VIDEO = "video",
    AVATAR = "avatar",
    TAG = "tag",
    GENRE = "genre",
    PUBLISHER = "publisher",
    CONTENT = "content",
    UI_HOME = "ui_home",
    STATIC = "static",
    COUNTRY = "country",
    PLANS = "plans",
    ALL = "all",
}
