import HomePage from "@/pages/AppUI/HomePage/Show/index";
import HomeUiForm from "@/pages/AppUI/HomePage/AddUpdate";
import HomeUiSection from "@/pages/AppUI/HomePage/Section";
import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export const ProtectedUIHomePage = withCheckPermissions(HomePage, PagesAccess.UI_HOME, OperationAccess.LIST);
export const ProtectedUIFormUpdate = withCheckPermissions(HomeUiForm, PagesAccess.UI_HOME, OperationAccess.UPDATE);
export const ProtectedUIFormCreate = withCheckPermissions(HomeUiForm, PagesAccess.UI_HOME, OperationAccess.CREATE);
export const ProtectedUISectionUpdate = withCheckPermissions(HomeUiSection, PagesAccess.UI_HOME, OperationAccess.UPDATE);
export const ProtectedUISectionCreate = withCheckPermissions(HomeUiSection, PagesAccess.UI_HOME, OperationAccess.CREATE);
