import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import DashboardHeader from "@/components/app/DashboardHeader";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export default function GenreHeader() {
    const createAccess = useCheckPermissionAccess(PagesAccess.GENRE, OperationAccess.CREATE);

    return (
        <DashboardHeader
            title="Genre"
            titleSuffix={
                createAccess ? (
                    <Button
                        color="primary"
                        component={Link}
                        to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ADD_NEW.ROOT.ABSOLUTE}
                        variant="contained"
                    >
                        Add New Genre
                    </Button>
                ) : null
            }
        >
            <Typography className="page-title">Genre List</Typography>
            <Typography component="p">List of all genres in the table.</Typography>
            <Typography component="p">Genres are based on being education and entertainment.</Typography>
            <Typography component="p">You can add, edit, and delete genres.</Typography>
            <Typography component="p">Additionally, you can search, sort, and filter genres in the table.</Typography>
        </DashboardHeader>
    );
}
