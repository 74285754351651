export type Order = "asc" | "desc";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
    // eslint-disable-next-line no-unused-vars
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
