import { Box, Button, TextField, SelectChangeEvent, FormControl, InputLabel, MenuItem, Select, Grid } from "@mui/material";
import { FilterList, FilterListOff } from "@mui/icons-material";
import { useState } from "react";

import { UserFiltersStyle } from "./style";

import { USER_ROLE } from "@/constants/enums/user";
import { UserRoles } from "@/constants/permissions";

interface IDefaultValues {
    search: string;
    role: USER_ROLE | null;
    active: number | null;
    emailVerified: number | null;
}

export const defaultValues: IDefaultValues = {
    search: "",
    role: null,
    active: null,
    emailVerified: null,
};

// eslint-disable-next-line no-unused-vars
export default function GenreFilters({ onApply }: { onApply: (data: typeof defaultValues) => void }) {
    const [fields, setFields] = useState(defaultValues);
    const [filterActivated, setFilterActivated] = useState<boolean>(false);

    const handleApply = () => onApply && onApply(fields);
    const toggleFilters = () => setFilterActivated((prev) => !prev);
    const handleReset = () => {
        setFields(defaultValues);
        if (onApply) onApply(defaultValues);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        const name = event.target.name;
        const value = event.target.value;

        setFields((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    return (
        <UserFiltersStyle>
            <Box className="filter-wrapper">
                {filterActivated && (
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item lg={2} md={4} xs={12}>
                            <TextField
                                fullWidth
                                className="input field "
                                label="Search"
                                name="search"
                                placeholder="Enter some text"
                                size="small"
                                value={fields.search}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item lg={2} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="select-label">Status</InputLabel>
                                <Select
                                    id="select"
                                    label="state"
                                    labelId="select-label"
                                    name="active"
                                    value={String(fields.active)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null as any}>All</MenuItem>
                                    <MenuItem value={1 as any}>Active </MenuItem>
                                    <MenuItem value={0 as any}>Deactivate</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="role-label">Roles</InputLabel>
                                <Select
                                    id="select"
                                    label="Role"
                                    labelId="role-label"
                                    name="role"
                                    value={String(fields.role)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null as any}> All</MenuItem>
                                    {UserRoles.map((role) => (
                                        <MenuItem key={role} value={role}>
                                            {role}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} xs={12}>
                            <FormControl fullWidth className="select field" size="small">
                                <InputLabel id="email-label">Email Confirm</InputLabel>
                                <Select
                                    id="emailVerified"
                                    label="Email Confirmed"
                                    labelId="email-label"
                                    name="emailVerified"
                                    value={String(fields.emailVerified)}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={null as any}> All</MenuItem>
                                    <MenuItem value={1 as any}> Confirmed </MenuItem>
                                    <MenuItem value={0 as any}> Not Confirmed </MenuItem>
                                </Select>
                            </FormControl>
                            {/* <FormControlLabel
                                className="select field"
                                control={
                                    <Checkbox
                                        checked={fields.emailVerified || false}
                                        inputProps={{ "aria-label": "controlled", name: "emailVerified" }}
                                        onChange={handleChangeCheckbox}
                                    />
                                }
                                label="Email Confirmed"
                            /> */}
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Box className="actions">
                                <Button className="action-btn" variant="contained" onClick={handleApply}>
                                    Apply
                                </Button>
                                <Button className="action-btn" variant="contained" onClick={handleReset}>
                                    reset
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
            <Button
                className="filter-btn"
                startIcon={filterActivated ? <FilterListOff /> : <FilterList />}
                variant="contained"
                onClick={toggleFilters}
            >
                Filters
            </Button>
        </UserFiltersStyle>
    );
}
