import { create } from "zustand";
import { isEmpty } from "lodash";

import { OperationAccessList, PageAccessList } from "@/constants/permissions";
import { IAuthenticatedUser, IPermissionModel, IPermissions } from "@/api/types/auth";
import { PagesAccess, OperationAccess } from "@/constants/enums/permissions";

/*
 * BECAUSE OF THE ROULES ADMINISTRATOR AND SUPERADMIN
 * we should generate the permissions based on acccess and block
 */

const convertPermissions = (permissionData: IPermissionModel): IPermissions => {
    const Permissions: IPermissions = {};
    const AccessLevel = permissionData.access;
    const BlockLevel = permissionData.block;

    if (!isEmpty(BlockLevel)) {
        const allPageAccess = AccessLevel[PagesAccess.ALL];

        // * create all posible access levels
        if (allPageAccess) {
            // remove block operations for list of possible operations for later use

            if (AccessLevel[PagesAccess.ALL].includes(OperationAccess.ALL)) {
                // all pages all operations in this case
                //  but do not include the blocked ones
                PageAccessList.forEach((page) => {
                    Permissions[page] = OperationAccessList.filter((oper) => !BlockLevel[PagesAccess.ALL].includes(oper));
                });
            } else {
                // all pages but only provided operations but do not include the blocked ones
                PageAccessList.forEach((page) => {
                    Permissions[page] = AccessLevel[PagesAccess.ALL].filter(
                        (oper) => !BlockLevel[PagesAccess.ALL].includes(oper)
                    );
                });
            }
        } else {
            const pages = Object.keys(AccessLevel);

            // if we have the same page in block we should filter it
            pages.forEach((page) => {
                if (page.includes(OperationAccess.ALL)) {
                    Permissions[page] = OperationAccessList.filter(
                        (oper) => BlockLevel[page] && !BlockLevel[page].includes(oper)
                    );
                } else {
                    Permissions[page] = AccessLevel[page].filter(
                        (oper) => BlockLevel[page] && !BlockLevel[page].includes(oper)
                    );
                }
            });
        }
    } else {
        // block was empty we just return actual access alone
        return permissionData.access;
    }

    // eslint-disable-next-line no-console
    console.log("Permissions", Permissions);

    return Permissions;
};

export interface IAuthStore {
    user: IAuthenticatedUser | null;
    userPermissions: IPermissions | null;
    actions: {
        // eslint-disable-next-line no-unused-vars
        setUser: (value: IAuthenticatedUser) => void,
        clearUser: () => void,
        // eslint-disable-next-line no-unused-vars
        setUserPermissions: (values: IPermissionModel) => void,
        clearUserPermissions: () => void,
    };
}

export const useAuthStore = create<IAuthStore>((set) => ({
    user: null,
    userPermissions: null,
    actions: {
        setUser: (value) => set({ user: value }),
        clearUser: () => set({ user: undefined }),
        setUserPermissions: (values) => set({ userPermissions: convertPermissions(values) }),
        clearUserPermissions: () => set({ userPermissions: null }),
    },
}));

export const useAuthActions = () => useAuthStore((store) => store.actions);
export const useUser = () => useAuthStore((store) => store.user);
export const useUserPermissions = () => useAuthStore((store) => store.userPermissions);
