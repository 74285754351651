import { Box, Button, Grid, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

// import Seasons from "./Season";
import SessionCard from "./SeasonCard";
import {
    ContentShowBackgroundStyled,
    ContentShowContentDescriptionStyled,
    ContentShowContentGenresStyled,
    ContentShowContentStyled,
    ContentShowContentTagsStyled,
    ContentShowContentTitleBadgesStyled,
    ContentShowContentTitleStyled,
    ContentShowImageLandscapeStyled,
    ContentShowImageStyled,
    ContentShowSidebarStyled,
    ContentShowStyled,
    ContentShowStyledWrapper,
    ContentShowVideoButtonStyled,
} from "./styled";

import { contentService } from "@/api/services/content";
import DashboardHeader from "@/components/app/DashboardHeader";
import InitLoading from "@/components/app/Loading/InitLoading";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IContentShowResponse } from "@/api/types/content";
import { IParams } from "@/types/params";
import { getAxiosError } from "@/utils/get-axios-error";
import { CONTENT_TYPE } from "@/constants/enums/content-type";
import { durationFormatter } from "@/utils/duration-formatter";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useContentList } from "@/api/hooks/useContents";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

const ContentShow = () => {
    const { id } = useParams<IParams>();
    const contentId = Number(id);
    const listAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.LIST);
    const editAccess = useCheckPermissionAccess(PagesAccess.CONTENT, OperationAccess.UPDATE);

    const { contentList } = useContentList(contentId);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<IContentShowResponse>();

    const handlePlayVideo = (videoId: number) => {
        modalActions.addModal(MODAL_TYPES.VIDEO_PLAYER_MODAL, { header: "VideoPlayer", width: "632px", videoId });
    };

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await contentService.show(contentId);

            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <InitLoading />;

    if (!data) return <>There is no data!</>;

    return (
        <ContentShowStyledWrapper>
            <DashboardHeader
                subTitle={data.ml_name.ar}
                title={data.ml_name.en}
                titleSuffix={
                    <Stack direction={"row"} spacing={2}>
                        {editAccess ? (
                            <Button
                                component={Link}
                                to={
                                    ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS[
                                        data.typeId === CONTENT_TYPE.SERIES ? "UPDATE_SERIES" : "UPDATE_MOVIE"
                                    ].EDIT_BY_ID(data.id).ABSOLUTE
                                }
                                variant="contained"
                            >
                                Edit Content
                            </Button>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.ABSOLUTE}>
                                Back To Content List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <ContentShowStyled>
                {data.thumbnailPortrait ? (
                    <ContentShowBackgroundStyled>
                        <img alt="thumbnails" src={data.thumbnailPortrait.url} />
                    </ContentShowBackgroundStyled>
                ) : null}
                <ContentShowSidebarStyled>
                    {data.thumbnailPortrait ? (
                        <ContentShowImageStyled>
                            <img alt="thumbnails" src={data.thumbnailPortrait.url} />
                        </ContentShowImageStyled>
                    ) : null}
                    {data.thumbnailLandscape ? (
                        <ContentShowImageLandscapeStyled>
                            <img alt="thumbnails" src={data.thumbnailLandscape.url} />
                        </ContentShowImageLandscapeStyled>
                    ) : null}
                    {data.mainVideo?.id ? (
                        <ContentShowVideoButtonStyled onClick={() => handlePlayVideo(data?.mainVideo?.id as number)}>
                            <PlayCircleIcon />
                            Main Video
                        </ContentShowVideoButtonStyled>
                    ) : null}
                    {data.trailerVideo?.id ? (
                        <ContentShowVideoButtonStyled onClick={() => handlePlayVideo(data?.trailerVideo?.id as number)}>
                            <PlayCircleIcon />
                            Trailer Video
                        </ContentShowVideoButtonStyled>
                    ) : null}
                </ContentShowSidebarStyled>
                <ContentShowContentStyled className="show-content-styled">
                    <ContentShowContentTitleStyled>
                        <h2>{data.ml_name.en}</h2>
                        <ContentShowContentTitleBadgesStyled>
                            {data.imdb && <span className="imdb">IMDB: {data.imdb}/10</span>}
                            {data.isEducation ? <span>Education</span> : null}
                            {data.isEntertainment ? <span>Entertainment</span> : null}
                            {data.length && data.length > 0 ? (
                                <span className="text">{durationFormatter(data.length)}</span>
                            ) : null}
                        </ContentShowContentTitleBadgesStyled>
                        {data.ml_name?.ar ? <h4>{data.ml_name.ar}</h4> : null}
                    </ContentShowContentTitleStyled>
                    {data.ml_description?.en || data.ml_description?.ar ? (
                        <ContentShowContentDescriptionStyled>
                            {data.ml_description.en ? <p>{data.ml_description.en}</p> : null}
                            {data.ml_description.ar ? <p>{data.ml_description.ar}</p> : null}
                        </ContentShowContentDescriptionStyled>
                    ) : null}
                    {data.tags.length ? (
                        <ContentShowContentTagsStyled>
                            <span>Tags</span>
                            <div>
                                {data.tags.map((item) => (
                                    <span key={item.id}>{item.ml_title.en}</span>
                                ))}
                            </div>
                        </ContentShowContentTagsStyled>
                    ) : null}
                    {data.genres.length ? (
                        <ContentShowContentGenresStyled>
                            <span>Genres</span>
                            <div>
                                {data.genres.map((item) => (
                                    <span key={item.id}>{item.ml_title.en}</span>
                                ))}
                            </div>
                        </ContentShowContentGenresStyled>
                    ) : null}
                </ContentShowContentStyled>
            </ContentShowStyled>

            {data.typeId === CONTENT_TYPE.SERIES ? (
                <Box className="show-sessions">
                    <Grid container spacing={2}>
                        {contentList.map((Season) => {
                            return (
                                <Grid key={Season.id} item md={3} xs={12}>
                                    <SessionCard Season={Season} serialId={Number(id)} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            ) : null}
        </ContentShowStyledWrapper>
    );
};

export default ContentShow;
