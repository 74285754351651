import withCheckPermissions from "@/layouts/GeneralLayoutWrapper/withCheckPermissions";
import TagList from "@/pages/ContentManagement/Tag";
import TagForm from "@/pages/ContentManagement/Tag/AddUpdate";
import GenreList from "@/pages/ContentManagement/Genre";
import GenreForm from "@/pages/ContentManagement/Genre/AddUpdate";
import ContentList from "@/pages/ContentManagement/Content";
import AddMovieFrom from "@/pages/ContentManagement/Content/AddMovie";
import AddSeries from "@/pages/ContentManagement/Content/AddSeries";
import ContentShow from "@/pages/ContentManagement/Content/Show";
import Publisher from "@/pages/ContentManagement/publisher";
import PublisherForm from "@/pages/ContentManagement/publisher/form";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

export const ProtectedContentList = withCheckPermissions(ContentList, PagesAccess.CONTENT, OperationAccess.LIST);
export const ProtectedAddMovieFrom = withCheckPermissions(AddMovieFrom, PagesAccess.CONTENT, OperationAccess.CREATE);
export const ProtectedUpdateMovieFrom = withCheckPermissions(AddMovieFrom, PagesAccess.CONTENT, OperationAccess.UPDATE);
export const ProtectedAddSeries = withCheckPermissions(AddSeries, PagesAccess.CONTENT, OperationAccess.CREATE);
export const ProtectedUpdateSeries = withCheckPermissions(AddSeries, PagesAccess.CONTENT, OperationAccess.UPDATE);
export const ProtectedContentShow = withCheckPermissions(ContentShow, PagesAccess.CONTENT, OperationAccess.LIST);

export const ProtectedTagList = withCheckPermissions(TagList, PagesAccess.TAG, OperationAccess.LIST);
export const ProtectedTagFormAdd = withCheckPermissions(TagForm, PagesAccess.TAG, OperationAccess.CREATE);
export const ProtectedTagFormUpdate = withCheckPermissions(TagForm, PagesAccess.TAG, OperationAccess.UPDATE);

export const ProtectedGenreList = withCheckPermissions(GenreList, PagesAccess.GENRE, OperationAccess.LIST);
export const ProtectedGenreFormUpdate = withCheckPermissions(GenreForm, PagesAccess.GENRE, OperationAccess.UPDATE);
export const ProtectedGenreFormAdd = withCheckPermissions(GenreForm, PagesAccess.GENRE, OperationAccess.CREATE);

export const ProtectedPublisher = withCheckPermissions(Publisher, PagesAccess.PUBLISHER, OperationAccess.LIST);
export const ProtectedPublisherAdd = withCheckPermissions(PublisherForm, PagesAccess.PUBLISHER, OperationAccess.CREATE);
export const ProtectedPublisherEdit = withCheckPermissions(PublisherForm, PagesAccess.PUBLISHER, OperationAccess.UPDATE);
