import { Access, Page } from "./enums/permissions";

export const UserRoles = [
    "SuperAdmin",
    "Administrator",
    "Content Manager",
    "Customer Service",
    "Analytics Manager",
    "Finance Manager",
];

export const OperationAccessList: Access[] = ["List", "Detail", "Create", "Update", "Delete", "Self", "All"];
export const PageAccessList: Page[] = [
    "user",
    "customer",
    "image",
    "video",
    "avatar",
    "tag",
    "genre",
    "publisher",
    "content",
    "ui_home",
    "static",
    "country",
    "plans",
    "all",
];
