import { styled } from "@mui/material";

export default styled("div")(`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .page-header {
        margin: 1em 0;
        display: flex;
        flex-direction: rtl;
        align-items:center;
        justify-content: space-between;

        .back {
            max-height: 45px;
        }
    }


    .page-content {
        position: relative;
        margin: 0 auto;
        width: 100%;
        min-height: 560px;
        display: flex;
        flex-direction: column;

        .serial-list {
            width: 100%;
            width: 100%;
            border-radius: 16px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: flex-start;
        }
    }
`);

export const SerialCardStyle = styled("div")(
    ({ theme }) => `

    display: inline-flex;
    width: 100%;
    flex-direction: column;
    margin: 8px;
    min-width: 300px;
    max-width: 340px;
    background: ${theme.palette.background.paper};
    border-radius: 16px;
    overflow:hidden;
    border: 1px solid ${theme.palette.grey[300]};

    .card-header, .card-content, .card-footer {
        padding: .5em;
    }

    .card-header {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: ${theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[100]};

        .session-number {
            width: 100%;
            font-size: 30px;
            color:  ${theme.palette.grey[400]};
        }

        .name {
            font-weight: bold;
            font-size: 30px;
            margin:0;
            width:100%;
            color:  ${theme.palette.grey[700]};
        }
       
    }

    .card-content, .card-footer {

        .text {
            display: flex;
            flex-direction: row;
            align-items:  center;
            font-size: 12px;
            margin-bottom: 10px;

            &.description {
                span.value {
                    text-overflow: ellipsis;
                    overflow: hidden; 
                    width: 160px; 
                    height: 1.2em; 
                    white-space: nowrap;
                }
            }

            span.label {
                font-weight: normal;
                margin-inline-end: 5px;
            }

            span.value {
                font-weight: bold;
            }
        }
    }

    .card-footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

         .actions {
            min-width: 160px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
        }
    }

`
);
