import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { createData } from "./helper";
import CustomerInfo from "./info";
import CustomerPaymentHistory from "./paymentHistory";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IPaymentHistoryParams } from "@/api/types/payments";
import { IParams } from "@/types/params";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { useCustomerById } from "@/api/hooks/useCustomer";
import usePaymentHistory from "@/api/hooks/usePaymentHistory";
import { OperationAccess, PagesAccess } from "@/constants/enums/permissions";

const CustomerShow = () => {
    const { id } = useParams<IParams>();
    const listAccess = useCheckPermissionAccess(PagesAccess.USER, OperationAccess.LIST);
    const editAccess = useCheckPermissionAccess(PagesAccess.USER, OperationAccess.UPDATE);

    const { data: customerData } = useCustomerById(Number(id));

    const [params, setParams] = useState<IPaymentHistoryParams>({
        skip: 0,
        take: 10,
        filter: { userId: Number(id) },
    } as IPaymentHistoryParams);

    const { data: paymentsData, isFetching, isLoading } = usePaymentHistory(params);

    const handleChange = useCallback(({ page, rowsPerPage }: { page?: number, rowsPerPage?: number }) => {
        setParams((prev) => {
            return {
                ...prev,
                skip: Number(page) * Number(rowsPerPage),
                take: rowsPerPage || 10,
            };
        });
    }, []);

    if (!customerData) return <h5>Oops! Something Went Wrong!</h5>;

    const payments = paymentsData?.data?.items || [];
    const totalItems = paymentsData?.data.pagination.totalItems;
    const data = customerData?.data || {};

    return (
        <>
            <DashboardHeader
                title={`${data.firstName} ${data.lastName}`}
                titleSuffix={
                    <Stack direction={"row"} spacing={2}>
                        {editAccess ? (
                            <Button
                                component={Link}
                                to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.UPDATE.EDIT_BY_ID(data.id).ABSOLUTE}
                                variant="contained"
                            >
                                Edit Customer
                            </Button>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE}>
                                Back To Customer List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <CustomerInfo data={data} />
                <br />
                <Typography>
                    <b>Payment history</b>
                </Typography>
                <CustomerPaymentHistory
                    data={payments.map((payment) => createData(payment))}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    page={Number(params.skip) / Number(params.take)}
                    rowsPerPage={Number(params.take)}
                    totalItems={totalItems || 0}
                    onChange={handleChange}
                />
            </DashboardContent>
        </>
    );
};

export default CustomerShow;
